.CardGroup {
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;
	color: #FF4F4F;
}

.CardGroup h1{
	margin-bottom: 0;
}

.ReservationCards {
	padding-top: 20px;
	padding-bottom: 10px;
  background-color: white;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	box-shadow: inset 0 0 4px grey;
	border-radius: 4px;

	transition: all .5s;
	overflow: hidden;
}

.ReservationCards h1{
}