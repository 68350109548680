.StoreCards {
	margin-top: 20px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
}

.StoreCards div{
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
}
.StoreCards input{
	max-width: 70%;

}
@media(max-width: 499px) {
	.StoreCards {

	}
}
/*wide screen*/
@media(min-width: 500px) {
	.StoreCards {
	  -webkit-column-count: 2;
	  -moz-column-count: 2;
	  column-count: 2;
	  -webkit-column-width: 200px;
    -moz-column-width: 200px;
    column-width: 200px;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
	}
	.StoreCards div {
		-webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
	}
}
