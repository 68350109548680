.ShopperBrowse {
	text-align: center;
}

.ShopperBrowse button {
 margin-left: auto;
 margin-right: auto;
 margin-bottom: 20px;
}
.ShopperBrowse h2{
 margin: 0;
 color: #FF4F4F;
 display:table-cell;
}

.StoreCards {
  background-color: white;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	overflow-x: hidden;
	overflow-y: auto;
	text-align: center;
	box-shadow: inset 0 0 4px grey;
	border-radius: 5px;
	padding-top: 10px;
	margin-bottom: 20px;
}

.StoreCards input {
	margin-left: auto;
	margin-right: auto;
	max-width: 70%;

}

@media(max-width: 499px) {
	.StoreCards {
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}
}
