.StorePage {
	text-align: center;
	max-width: 500px;
	margin-right: auto;
	margin-left: auto;
	color: #FF4F4F;
	padding-right: 20px;
	padding-left: 20px;
}
.StorePage img {
	width: 50%;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: auto;
	margin-right: auto;
}
.StorePage h1 {
	font-size: 2em;
	margin-bottom: 5px;
	padding-bottom: 0px;
}
.StorePage h3 {
	margin-top: 0;
	margin-bottom: 0;
	padding-bottom: 0;
	color: #FF4F4F;
}
.StorePage p {
}
.LearnMore p {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	font-size: 1.5em;
}
.BackgroundCard {
  padding: 20px;
  box-shadow: 1px 1px 4px 0px grey;
  background-color: white;
  max-width: 540px;
  margin-left:auto;
  margin-right:auto;
  margin-bottom: 20px;
  border-radius: 4px;
}
/*mobile device*/
@media(max-width: 499px) {
	.BackgroundCard {
  margin-bottom: 0px;
  border-radius: 0px;
}


}
