.Form {
	text-align: center;
	border-style: solid;
	border-width: 2px;
	border-color: white;
	border-radius: 10px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: 40px;
}
.Form.Alt {
	border-color: #FF4F4F;
	background-color: white;
}
.Form h2 {
	text-align: center;
	color: white;
}

.Form p{
	font-size: 1.5em;
	color: white;
}
.DropDown{
	display: inline;
}
.DropDown p, select{
	margin:5px;
	padding:0;
	display: inline-block;
}

.Form.Alt h2{
	color: #FF4F4F;
}
.Form input {
	margin-top: 10px;
  margin-left: auto;
	margin-right: auto;
}

.Form input:disabled {
  margin:0;
  padding:0;
  opacity:0;
	transform: scaleY(0);
	border-width: 0;
  cursor:default;
}
.Form.Alt p{
	color: #FF4F4F;
}

.Form button {
	margin-top: 10px;
}

/*mobile device*/
@media(max-width: 499px) {
	.Form {
		max-width:420;
	}
}
/*wide screen*/
@media(min-width: 500px) {
	.Form {
		width:420px;
	}
}