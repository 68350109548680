.Modal {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	color:white;
}
.Modal input {
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	max-width: 90%
}
.Modal button {
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: 20px;
}