.Modal {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	color:white;
}

.Modal button {
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	margin-bottom: 10px;
}
.Modal svg {
	position: absolute;
	top: 10px;
	left: 10px;
}
.Modal svg :hover {
	cursor: pointer;
}