.ReservationCard {
	box-shadow: 0 0 4px grey;
	margin-right: 20px;
	margin-left: 20px;
	border-radius: 4px;
	margin-top: 0;
	white-space: nowrap;
	display: flex;
	padding-left: 10px;
	justify-content: space-between;
	margin-bottom: 10px;
}

.ReservationCard.Hidden {
	height: 0;
	margin-bottom: 0;
	transition: all .5s;
}
.ReservationCard.Hidden *{
	height: 0;
	margin-bottom: 0;
	margin-top: 0;
	opacity: 0;
}

.ReservationCard.CheckedIn {
	color: #08D853;
}
.ReservationCard.CheckedIn.NoShow {
	color: #FF4F4F;
	text-decoration: line-through;
}
.ReservationCard * {
	display: inline;
	margin-top: auto;
	margin-bottom: auto;
}

.Info p{
	margin-left: 10px;
}



.Buttons svg{
	padding-left: 10px;
	padding-right:10px;
	margin-top: 5px;
}

.Buttons svg:hover {
	cursor: pointer;
}