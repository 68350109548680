.DropDown {
	background-color: transparent;
	color: white;
	font-size: 1.5em;
	border-width: 0;
	border-bottom-width: 2px;
	border-color: white;
  background-repeat: no-repeat;
  -webkit-border-radius: 0px;
  -webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
}

.DropDown.Alt {
	background-color: transparent;
	color: #FF4F4F;
	border-color: #FF4F4F;
}

.DropDown:hover {
	cursor: pointer;
}


.DropDown:focus {
	outline: none;
}