.Modal {
    position: absolute;
    z-index: 500;
    background-image: linear-gradient(-70deg, #FF4F4F, #DBDC9C);
    width: 70%;
    box-shadow: 0px 0px 4px grey;
    padding: 20px;
    left: 50%;
    top: 25%;
    margin-left: calc(-35% - 20px);
    border-width: 0;
    border-radius: 10px;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: 50%;
        margin-left: calc(-250px - 20px);
    }
}