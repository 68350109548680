.TextField {
	border-bottom: 2px;
	border: 0;
	background: transparent;
	border-bottom-width: thin;
	border-style: solid;
	border-color: white;
	color: white;
	font-size: 1.5em;
	transition: all .5s ease-out;
	-webkit-border-radius: 0px;
  -webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;

}
.TextField.Alt.Inset {
	background-color: white;
	box-shadow: inset 0 0 2px grey;
	border-radius: 4px;
	border-bottom: 0;
	padding-left: 5px;
}
.TextField.Alt {
	border-color: #FF4F4F;
	
color: #FF4F4F;
}
.TextField.Alt::placeholder {
	color:#FF4F4F;
}
.TextField.Alt:focus {
	color:#FF4F4F;
}
.TextField::placeholder {
	color:white;
	opacity: 0.5;
}
.TextField:focus {
	color:white;
	outline:none;
}
