.FrontPage {
		align-items: center;
		text-align: left;
		padding-right: 20px;
		padding-left: 20px;
	}
.FrontPage *{
		margin-top: 20px;
		margin-bottom: 20px;
}
.FrontPage p {
	color:white;
	font-size: 1.5em;
}
.FrontPage a {
	margin-left: 0;
	margin-top: 0;
	color: white;
}

/*mobile device*/
@media(max-width: 499px) {
	.FrontPage h1 {
		font-size: 2em;
	}
}
/*wide screen*/
@media(min-width: 500px) {
	.FrontPage {
		margin:auto;
		max-width: 500px;
	}

}
