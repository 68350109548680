.ReservationForm {
	text-align: center;
	max-width: 500px;
	margin-bottom: 20px;
	color: #FF4F4F;
	padding-right: 20px;
	padding-left: 20px;
	border-style: solid;
	border-width: 2px;
	max-width: 500px;
	border-radius: 10px;
	padding-top: 20px;
	padding-bottom: 20px;
	position: relative;
	transition: all .5s ease-out;
  background-color: white;	
}

.disabledDate {
  pointer-events: none;
  
}
.ReservationForm div {
	transition: all .5s ease-out;
}
.ReservationForm svg {
	transition: all .5s ease-out;
}

.ReservationForm p {
	font-size: 1.5em;
}

.ReservationForm select {
	margin-top: 0;
	font-size: 1.5em;
}

.ReservationForm input {
	max-width: 60%;
}

.ReservationForm h3 {
	margin-top: 0px;
	margin-bottom: 10px;
}

.Persons {
	display: inline-block;
	display: table;
	margin-left: auto;
	margin-right: auto;

}

.Persons p {
	display: table-cell;
	margin: 0;
	padding: 0;
	font-size: 1.5em;
}

.Persons select {
	display: table-cell;
	margin:0;
	margin-left: 10px;
	margin-right: 10px;
	padding: 0;
	font-size: 1.5em;
}

.TextField {
	text-align: center;
	border: 0;
	background: transparent;
	border-bottom-width: 2px;
	border-style: solid;
	border-color: white;
	color: white;
	font-size: 1.5em;
	transition: all .5s ease-out;
	-webkit-border-radius: 0px;
  -webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;

}

.TextField.Alt {
	border-color: #FF4F4F;
	color: #FF4F4F;
}
.TextField.Alt::placeholder {
	color:#FF4F4F;
}
.TextField.Alt:focus {
	color:#FF4F4F;
}
.TextField::placeholder {
	color:white;
	opacity: 0.5;
}
.TextField:focus {
	color:white;
	outline:none;
}
