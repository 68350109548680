.Button {
	color: #FF4F4F;
	background-color: white;
	border-radius: 4px;
	font-size: 1.5em;
	border-width: 0;
	box-shadow: 5px 10px #FF4F4F;
	cursor: pointer;
}

.Button:hover{
	color: white;
	background-color:  #FF4F4F;
	box-shadow: 5px 10px white;
}
.Button:disabled{
	color: white;
	background-color:  grey;
	box-shadow: 5px 10px white;
	cursor: default;
}

.Button.Alt {
	color:white;
	background-color: #FF4F4F;
	box-shadow: 5px 10px #CCCCCCFF;
}

.Button.Alt:hover{
	color:white;
	background-image: linear-gradient(45deg, #FF4F4F, #DBDC9C);
	box-shadow: 5px 10px #CCCCCCFF;
}
.Button.Alt:disabled {
	color: white;
	background-image: none;
	background-color:  grey;
	box-shadow: 5px 10px #CCCCCCFF;
	cursor: default;
}