.LearnMore {
		align-items: center;
		text-align: left;
		padding-left:20px;
		padding-right: 20px;
	}
.LearnMore *{
		margin-top: 20px;
		margin-bottom: 20px%;
}
.LearnMore p {
	color:#FF4F4F;
	font-size: 1.5em;
}
.LearnMore h2 {
	color: #FF4F4F;
}

.LearnMore a {
	color: #FF4F4F;
}
.BackgroundCard {
  padding: 20px;
  box-shadow: 1px 1px 4px 0px grey;
  background-color: white;
  max-width: 540px;
  margin-left:auto;
  margin-right:auto;
  margin-bottom: 20px;
  border-radius: 4px;
}

.BookButton {
	display: inline-block;
	border: none;
	color:#FF4F4F;
	border-radius: 4px;
	border: solid 2px #FF4F4F;
	text-decoration: none;
	text-align: center;
	padding: 5px;
	margin: 0;
	box-sizing:border-box;
}

.BookButton:hover{
	cursor: pointer;
	border: none;
	color:white;
	border: solid 2px white;
	background-image: linear-gradient(-70deg, #FF4F4F, #DBDC9C);
	background-size: 100%;
	border-radius: 4px;
}

.BookButton h3 {
	margin: 0px;
}
/*mobile device*/
@media(max-width: 499px) {
  .BackgroundCard {
    margin-bottom: 0px;
    border-radius: 0px;
  }
}
/*wide screen*/
@media(min-width: 500px) {
	.LearnMore {
		margin:auto;
		max-width: 500px;
	}

}
