.StoreCard {
	text-align: left;
	color: #FF4F4F;
	box-shadow: 0px 0px 4px  grey;
	max-width: 250px;
	padding-bottom: 10px;
	background-color: white;
	border-radius: 4px;
	overflow: hidden;
}
.StoreCard h2{
	padding-top: 10px;
	padding-left: 10px;
	padding-right:10px;
	padding-bottom: 0px;
}
.StoreCard p{
	margin-top: 0;
	padding-left: 10px;
	margin-bottom: 10px;
}
.StoreCard img{
	margin-top: 0;
	width: 100%;
}
/*mobile device*/
@media(max-width: 499px) {
	.StoreCard {
		max-width:70%px;
	}
}
/*wide screen*/
@media(min-width: 500px) {
	.StoreCard {

	}
}

