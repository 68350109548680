.Logo {
	margin-top: 0px;
	margin-bottom: 20px;
	font-size: 3em;
	margin-left: 20px;
  padding-top: 20px;
}
.Logo a{
	text-decoration: none;
	color: white;
}

.Alt {
  background: -webkit-linear-gradient(45deg, #FF4F4F, #DBDC9C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media(max-width: 499px) {
	.Logo {
		font-size: 2em;
	}
}
